import { UserMetadataAction, PrestonUser } from "@prestonly/preston-common";
import store from "@/store";
import Shepherd from "shepherd.js";
import availableTours from "@/tour";
import i18n from "@/i18n";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import _ from "lodash";

const PRIMARY_BTN_CLASSES = "v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary";
const SECONDARY_BTN_CLASSES = "v-btn v-btn--text theme--light v-size--small primary--text white";

const tourConfigs = {
  exercises: {
    startOnDemand: true,
  },
  example: {
    startOnDemand: true,
  },
};

export const startTour = async ({
  selectedTour,
  force = false,
  onDemand = false,
}: {
  selectedTour: keyof typeof availableTours;
  force?: boolean;
  onDemand?: boolean;
}): Promise<void> => {
  if (!(selectedTour in availableTours)) {
    return;
  }
  if (selectedTour in tourConfigs) {
    const tourConfig = tourConfigs[selectedTour];
    if (tourConfig.startOnDemand && !onDemand) {
      return;
    }
  }

  const user: PrestonUser = await store.getters["user/getUser"];
  const stepsSeenByUser = user.userMetadata.stepsSeen || [];

  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    exitOnEsc: true,
    keyboardNavigation: true,

    defaultStepOptions: {
      canClickTarget: false,
      modalOverlayOpeningPadding: 6,
      modalOverlayOpeningRadius: 6,
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 12] } }],
      },
      classes: "shadow-md bg-purple-dark v-dialog v-application",
      scrollTo: { behavior: "smooth", block: "center" },
      cancelIcon: {
        enabled: true,
      },
      when: {
        show: function () {
          const overlay = document.querySelector(".shepherd-modal-overlay-container");
          if (overlay) {
            overlay.addEventListener("click", () => {
              tour.cancel();
            });
          }
          const targetElement = this.getTarget() || document.documentElement;
          disableBodyScroll(targetElement);

          // inser progress indicator
          const currentStepElement = this.getElement();
          const currentStep = tour.getCurrentStep();
          if (!currentStepElement || !currentStep) {
            return;
          }
          const footer = currentStepElement.querySelector(".shepherd-footer");
          const insertTarget = currentStepElement.querySelector(".shepherd-button:last-child");
          if (!footer || !insertTarget) {
            return;
          }
          const progress = document.createElement("span");
          progress.className = "shepherd-progress";
          progress.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
          footer.insertBefore(progress, insertTarget);
        },
      },
    },
  });
  const steps = [...availableTours[selectedTour]].filter(({ step }) => force || !stepsSeenByUser.includes(step));
  if (steps.length === 0) {
    // User has already seen all steps
    return;
  }
  const lastStep = steps.pop();
  const firstStep = steps.shift();

  if (firstStep) {
    tour.addStep({
      attachTo: { element: !firstStep.intro ? `[tour-step=${firstStep.step}]` : undefined, on: "bottom" },
      text: i18n.t(firstStep.textKey).toString(),
      buttons: [
        {
          action() {
            this.complete();
          },
          classes: SECONDARY_BTN_CLASSES,
          text: i18n.t("tour.skip").toString(),
        },
        {
          action() {
            this.next();
          },
          classes: PRIMARY_BTN_CLASSES,
          text: i18n.t("tour.next").toString(),
        },
      ],
    });
  }

  for (const { intro, step, textKey } of steps) {
    tour.addStep({
      attachTo: { element: !intro ? `[tour-step=${step}]` : undefined, on: "bottom" },
      text: i18n.t(textKey).toString(),
      buttons: [
        {
          action() {
            this.back();
          },
          classes: SECONDARY_BTN_CLASSES,
          text: i18n.t("tour.previous").toString(),
        },
        {
          action() {
            this.next();
          },
          classes: PRIMARY_BTN_CLASSES,
          text: i18n.t("tour.next").toString(),
        },
      ],
    });
  }

  if (lastStep) {
    const buttons = [
      {
        action() {
          tour.complete();
        },
        classes: PRIMARY_BTN_CLASSES,
        text: i18n.t("tour.finish").toString(),
      },
    ];

    // if at least one step before this one exists
    if (firstStep) {
      buttons.unshift({
        action() {
          tour.back();
        },
        classes: SECONDARY_BTN_CLASSES,
        text: i18n.t("tour.previous").toString(),
      });
    }

    tour.addStep({
      attachTo: { element: !lastStep.intro ? `[tour-step=${lastStep.step}]` : undefined, on: "bottom" },
      text: i18n.t(lastStep.textKey).toString(),
      buttons,
    });
  }

  const tourCompleted = () => {
    clearAllBodyScrollLocks();
    store.dispatch("user/patchUser", {
      action: UserMetadataAction.TOUR_STEPS_SEEN,
      payload: {
        stepsSeen: _.uniq([...stepsSeenByUser, ...availableTours[selectedTour].map(({ step }) => step)]),
      },
    });
  };

  tour.on("complete", tourCompleted);
  tour.on("cancel", tourCompleted);

  tour.start();
};
