
import Vue from "vue";
import { isEmpty } from "lodash";

import { getInstance } from "@/auth";
import { LangCode, PrestonUser } from "@prestonly/preston-common";
import HeaderAuthButtons from "@/components/base/HeaderAuthButtons.vue";
import { startTour } from "@/utils/startTour";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "header-user-menu",
  components: { HeaderAuthButtons, Btn },
  computed: {
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    user(): PrestonUser | null {
      const user = this.$store.getters["user/getUser"];
      if (user && !isEmpty(user)) {
        return user;
      }
      return null;
    },
    avatarFileName(): string {
      return this.user?.userMetadata.avatarFileName || "avatar0.svg";
    },
    availableTour() {
      return this.$route.meta?.["tour"] || null;
    },
  },
  methods: {
    async login() {
      const authService = getInstance();
      await authService.logout({ returnTo: window.location.origin });
      await authService.loginWithRedirect({ appState: { targetUrl: "/course" } });
    },
    startTour() {
      this.$nextTick(() => {
        if (!this.availableTour) {
          return;
        }
        startTour({ selectedTour: this.availableTour, force: true, onDemand: true });
      });
    },
    goToUserProfile() {
      this.$router.push({ name: "userProfile" });
    },
  },
});
