
import Vue from "vue";

export default Vue.extend({
  name: "HeaderAuthButtons",

  computed: {
    isAdminRoute() {
      return this.$route.meta?.isAdminPanel;
    },
    isCompanyAdminRoute() {
      return this.$route.meta?.isCompanyAdmin;
    },
  },

  methods: {
    goTo(): void {
      const name = this.isAdminRoute ? "dashboard" : "adminCourses";
      if (name === "dashboard") {
        this.$store.commit("course/resetState");
        this.$router.push({ name });
        return;
      }
      let route = this.$router.resolve({ name });
      window.open(route.href, "_blank");
    },
    goToCompany(): void {
      const name = this.isCompanyAdminRoute ? "dashboard" : "companyAdminUsers";
      if (name === "dashboard") {
        this.$store.commit("course/resetState");
        this.$router.push({ name });
        return;
      }
      this.$router.push({ name });
      // let route = this.$router.resolve({ name });
      // window.open(route.href, "_blank");
    },
    logout(): void {
      if (window && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("logoutAndLogin");
      } else {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      }
    },
  },
});
