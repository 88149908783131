import courses from "./courses.json";
import exercises from "./exercises.json";
import courseDetails from "./courseDetails.json";
import exerciseModeSelector from "./exerciseModeSelector.json";
import repetition from "./repetition.json";
import example from "./example.json";

export default {
  courses,
  exercises,
  courseDetails,
  exerciseModeSelector,
  repetition,
  example,
};
